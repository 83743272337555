import { StateCode, STATES } from '@shared/components/address-list/address-list-form.interface';

export interface SalesTaxReport {
  breakdown: {
    [key in keyof typeof STATES]: StateReport;
  };
  summary: {
    date: {
      from: string;
      to: string;
    };
    deductions_and_exemptions: number;
    gross: number;
    market_place_sales_tax: number;
    market_place_sales: number;
    sales_subject_to_sales_tax: number;
    total_sales_tax: number;
    total_sales_tax_due: number;
  };
}

export interface StateReport {
  has_nexus: boolean;
  enrolled_in_autofilling: boolean;
  origin_vs_destination: string;
  sales_tax_shipping: boolean;

  payment_schedule: StateReportPaymentSchedule;

  reports: {
    breakdown?: {
      state_tax_total: number,
      city_tax_total: number,
      county_tax_total: number,
      special_tax_total: number,
      breakdown_detail: [
        {
          taxRegion: string,
          gross: number,
          sales_subject_to_sales_tax: number,
          total_sales_tax: number,
          state_taxable_amount: number,
          city_taxable_amount: number,
          county_taxable_amount: number,
          special_taxable_amount: number
        },
        {
          taxRegion: string,
          gross: number,
          sales_subject_to_sales_tax: number,
          total_sales_tax: number,
          state_taxable_amount: number,
          city_taxable_amount: number,
          county_taxable_amount: number,
          special_taxable_amount: number
        }
      ],
      breakdown_detail_compressed: [
        {
          taxRegion: string,
          gross: number,
          sales_subject_to_sales_tax: number,
          total_sales_tax: number,
          state_taxable_amount: number,
          city_taxable_amount: number,
          county_taxable_amount: number,
          special_taxable_amount: number
        },
        {
          taxRegion: string,
          gross: number,
          sales_subject_to_sales_tax: number,
          total_sales_tax: number,
          state_taxable_amount: number,
          city_taxable_amount: number,
          county_taxable_amount: number,
          special_taxable_amount: number
        }
      ],
    },
    gross: number;
    sales_subject_to_sales_tax: number;
    market_place_sales: number;
    deductions_and_exemptions: number;
    total_sales_tax: number;
    market_place_sales_tax: number;
    total_sales_tax_due: number;
  };
}

export interface StateReportPaymentSchedule {
  title: string;
  start: string;
  end: string;
  filingDate: string;
  state_abbr: string;
  payment_schedule: string;
}

export interface EconomicNexus {
  state_abbr: StateCode;
  operation: 'OR' | 'AND';
  sales_threshold: number;
  transaction_threshold: number;
  current_transaction_amount: number;
  current_sales_amount: number;
  fiscal_bounds: Array<string>;
  fiscal_date: Array<string>;
  has_nexus_settings: boolean;
  has_reached_nexus: boolean;
  sales_percent: number;
  transaction_percent: number;
}

export enum SalesReportRouteFragment {
  actual = 'actual',
  expected = 'expected',
  insights = 'insights',
}

export interface StateInsightsReport {
  insights: {
    over_collected_transactions: InsightsTransaction;
    under_collected_transactions: InsightsTransaction;
    no_sales_tax_collected: InsightsTransaction;
    penny_variance: InsightsTransaction;
  };
  summary: {
    filing_period: string;
    total_transactions: number;
    variance_transactions: number;
    net_variance_amount: number;
  };
}

interface InsightsTransaction {
  transaction_ids: string[];
  value: number;
}